(<template>
  <article :class="{'in-progress': progressActive, 'dashboard--with-top-section': userIsEnterpriseMember}"
           class="dashboard is-progress-bar">
    <section class="dashboard__sections-wrapper">
      <enterprise-buttons v-if="userIsEnterpriseMember"
                          class="dashboard__top-section" />
      <hotline-info v-if="showHotline"
                    :lang-pair="norwArabicPair"
                    class="dashboard__inner-section dashboard__inner-section--hotline-mobile" />
      <section :class="{'in-progress': assignmentsProgress || searchAssignmentsProgress}"
               class="dashboard__section is-progress-bar">
        <assignment-search class="dashboard__inner-section dashboard__inner-section--full-size">
          <header v-if="!searchQuery"
                  class="dashboard__section-header">
            <div class="dashboard__switchers-cont">
              <button :class="{'dashboard__switcher--active': showFutureAssignments}"
                      class="dashboard__switcher"
                      @click="toggleAssignments">{{ $gettext('Current assignments') }}</button>
              <span class="dashboard__switchers-devider"></span>
              <button :class="{'dashboard__switcher--active': !showFutureAssignments}"
                      class="dashboard__switcher"
                      @click="toggleAssignments">{{ $gettext('Past assignments') }}</button>
            </div>
            <router-link :to="assignmentsLink"
                         class="dashboard__section-link">{{ $gettext('View all') }}</router-link>
          </header>
          <template v-if="isAssigns">
            <assignments-list :assignments-list="allAssignments"
                              :show-all-btn="{display: allAssignmentsPages > 1 && !searchQuery, link: assignmentsLink}"
                              class="dashboard__assignment-list" />
            <div v-if="showStatistic"
                 class="dashboard__assignment-counters-cont">
              <div v-for="counter in assignmentCounters"
                   :key="counter.name"
                   class="dashboard__assignment-counter">
                <p class="dashboard__assignment-counter-digit">{{ counter.value }}</p>
                <p class="dashboard__assignment-counter-name">{{ counter.name }}</p>
              </div>
            </div>
          </template>
          <empty-state v-else
                       :text="noAssignsText"
                       :list-class="true" />
        </assignment-search>
      </section>
      <section v-if="userIsEnterpriseMember"
               :class="{'in-progress': calendarProgress}"
               class="dashboard__section is-progress-bar">
        <div class="dashboard__inner-section dashboard__inner-section--full-size">
          <header class="dashboard__section-header">
            <div class="dashboard__switchers-cont">
              <button :class="{'dashboard__switcher--active': !tomorrowCalendar}"
                      class="dashboard__switcher"
                      @click="toggleCalendar">{{ $gettext('Today') }}</button>
              <span class="dashboard__switchers-devider"></span>
              <button :class="{'dashboard__switcher--active': tomorrowCalendar}"
                      class="dashboard__switcher"
                      @click="toggleCalendar">{{ $gettext('Tomorrow') }}</button>
            </div>
            <router-link :to="calendarLink"
                         class="dashboard__section-link">{{ $gettext('View calendar') }}</router-link>
          </header>
          <div class="dashboard__calendar-wrapper">
            <full-calendar :active-date="dateForCalendar"
                           :view="'timeGridDay'"
                           :events="tomorrowCalendar ? calendarJobsTomorrow : calendarJobs"
                           :column-header="false"
                           @startprogress="calendarProgress = true"
                           @stopprogress="calendarProgress = false" />
          </div>
        </div>
      </section>
      <section class="dashboard__section">
        <hotline-info v-if="showHotline"
                      :lang-pair="norwArabicPair"
                      class="dashboard__inner-section dashboard__inner-section--hotline" />
        <section class="dashboard__inner-section is-progress-bar"
                 :class="{'in-progress': favouritIntersProgress}">
          <header class="dashboard__section-header dashboard__section-header--with-select">
            <h2 class="dashboard__section-title">{{ $gettext('My favourite interpreters') }}</h2>
            <div class="dashboard__inter-filter">
              <span class="dashboard__lang-select-descr">{{ $gettext('Language:') }}</span>
              <sk-select :type="'language_from'"
                         :preselected-value="favouriteInterLang"
                         class="sk-select--lang dashboard__lang-select"
                         @csvaluechanged="changeFavouriteInterLang" />
            </div>
          </header>
          <template v-if="isfavouritInters">
            <interpreters-list :interpreters="favouritInters"
                               :render-amount="interAmount"
                               class="dashboard__interpreters-list" />
          </template>
          <template v-else>
            <empty-state :text="noFavouriteIntersText" />
          </template>
        </section>
        <section v-if="isNewsList"
                 class="dashboard__inner-section news is-progress-bar"
                 :class="{'dashboard__inner-section--full-size': showHotline}">
          <header class="dashboard__section-header">
            <h2 class="dashboard__section-title">{{ $gettext('News') }}</h2>
            <button v-if="lastPublishedNewsList.article"
                    class="dashboard__section-title-archive"
                    @click="openNewsList">{{ $gettext("View archive") }}</button>
          </header>
          <news-list :last-published="lastPublishedNewsList.article"
                     class="dashboard__interpreters-list" />
        </section>
        <section v-if="showdidYouKnowList && false"
                 class="dashboard__inner-section did-you-know">
          <header class="dashboard__section-header">
            <h2 class="dashboard__section-title">{{ $gettext('Frequently asked questions') }}</h2>
            <a :href="didYouKnowLink"
               class="dashboard__section-title-archive">{{ $gettext("View archive") }}</a>
          </header>
          <did-you-know-list :item-list="didYouKnowList" />
        </section>
      </section>
    </section>
  </article>
</template>)

<script>
  import {mapGetters, mapMutations, mapState} from 'vuex';
  import AssignmentsList from '@/components/assignment_components/dashboard/AssignmentsList';
  import InterpretersList from '@/components/dashboard_components/InterpretersList';
  import NewsList from '@/components/dashboard_components/NewsList';
  import DidYouKnowList from '@/components/dashboard_components/DidYouKnowList';
  import HotlineDashboard from '@/components/hotline/HotlineDashboard';
  import EmptyState from '@/components/dashboard_components/EmptyState';
  import FullCalendar from '@/components/calendars/FullCalendar';
  import AssignmentSearch from '@/components/assignment_components/dashboard/AssignmentSearch';
  import EnterpriseButtons from '@/components/dashboard_components/EnterpriseButtons';

  export default {
    asyncData({store, moment}) {
      let promiseArr = '';
      if (!store.state.b_DashboardStore.myAssignments.futureAssignments) {
        const params = {items: 10, page: 1};
        promiseArr = [...promiseArr, store.dispatch('b_DashboardStore/getDashboardFutureAssignments', params)];
      }
      if (!store.state.b_DashboardStore.favouritInterpreters.data) {
        promiseArr = [...promiseArr, store.dispatch('b_DashboardStore/getFavouritInterpreters')];
      }
      if (!store.state.b_DashboardStore.lastPublishedNewsList && store.getters['UserInfoStore/enterpriseId'] === 27) {
        promiseArr = [...promiseArr, store.dispatch('b_DashboardStore/getLastPublishedNewsList')];
      }
      // Needs to be redo as it fills the console with unnecessary errors. Error 401.
      // if (!store.state.b_DashboardStore.didYouKnowList) {
      //   promiseArr = [...promiseArr, store.dispatch('b_DashboardStore/getDidYouKnowList')];
      // }
      if (!store.state.b_DashboardStore.myAssignmentsStatistic) {
        promiseArr = [...promiseArr, store.dispatch('b_DashboardStore/getMyAssignmentsStatistic')];
      }
      if (!store.state.b_DashboardStore.assignStatisticByLang) {
        promiseArr = [...promiseArr, store.dispatch('b_DashboardStore/getAssignStatisticByLang')];
      }
      if (!store.state.b_DashboardStore.todayJobs) {
        const params = {view: 'day', date: moment().format('YYYY-MM-DD')};
        promiseArr = [...promiseArr, store.dispatch('b_DashboardStore/b_getTodayCalendarJobs', params)];
      }
      if (store.getters['UserInfoStore/canSeeHotline']) {
        const params = {lang_from_id: 3, lang_to_id: 1};
        if (!store.state.TTHotlineStore.interpreterCount && store.state.TTHotlineStore.interpreterCount !== 0) {
          promiseArr = [...promiseArr, store.dispatch('TTHotlineStore/getAvailableInterpreters', params)];
        }
        if (!store.state.TTHotlineStore.pinCodes) {
          promiseArr = [...promiseArr, store.dispatch('TTHotlineStore/getPinCodes')];
        }
        if (!store.state.TTHotlineStore.phones) {
          promiseArr = [...promiseArr, store.dispatch('TTHotlineStore/getPhones')];
        }
      }
      return promiseArr ? Promise.allSettled(promiseArr) : promiseArr;
    },
    components: {
      'enterprise-buttons': EnterpriseButtons,
      'full-calendar': FullCalendar,
      'interpreters-list': InterpretersList,
      'news-list': NewsList,
      'assignments-list': AssignmentsList,
      'did-you-know-list': DidYouKnowList,
      'hotline-info': HotlineDashboard,
      'empty-state': EmptyState,
      'assignment-search': AssignmentSearch
    },
    data() {
      return {
        clientWidth: 768,
        intervalId: '',
        calendarProgress: true,
        intervalCanUpdate: true,
        tomorrowCalendar: false,
        showFutureAssignments: true,
        progressActive: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsNotEnterpriseMember',
        'userIsEnterpriseMember',
        'enterpriseMemberJobsScope',
        'enterpriseName',
        'userCanSeeHotline',
        'userEmail',
        'isEmailVerificationRequired'
      ]),
      ...mapState('b_DashboardStore', {
        calendarJobs: (state) => state.todayJobs || {},
        calendarJobsTomorrow: (state) => state.tomorrowJobs || {},
        myAssignmentsStatistic: (state) => state.myAssignmentsStatistic || {},
        assignmentsProgress: (state) => state.myAssignments.progress,
        futureAssignmentsData: (state) => state.myAssignments.futureAssignments || {},
        pastAssignmentsData: (state) => state.myAssignments.pastAssignments || {},
        lastPublishedNewsList: (state) => state.lastPublishedNewsList || {},
        didYouKnowList: (state) => state.didYouKnowList || {}
      }),
      ...mapState('DashboardSearchStore', {
        searchAssignmentsData: ({searchAssignments}) => searchAssignments || {},
        searchQuery: ({searchQuery}) => searchQuery,
        searchAssignmentsProgress: ({progress}) => progress
      }),
      ...mapState('TTHotlineStore', {
        hotlinePinCodes: (state) => state.pinCodes || [],
        hotlinePhones: (state) => state.phones || [],
        interpreterCount: (state) => state.interpreterCount || ''
      }),
      myAssignmentsData() {
        return this.searchQuery
          ? this.searchAssignmentsData
          : this.showFutureAssignments
            ? this.futureAssignmentsData
            : this.pastAssignmentsData;
      },
      allAssignments() { return this.myAssignmentsData.jobs || []; },
      allAssignmentsPages() { return this.myAssignmentsData.pages || 0; },
      allAssignmentsAmount() { return this.myAssignmentsData.count || 0; },
      isAssigns() {
        return this.allAssignments.length || this.searchQuery || this.assignmentsProgress || this.searchAssignmentsProgress;
      },
      calendarLink() {
        return this.$makeRoute({
          name: 'BuyerCalendar',
          query: {
            view: 'week',
            date: this.tomorrowCalendar ? this.$moment().add(1, 'day').format('YYYY-MM-DD') : this.$moment().format('YYYY-MM-DD')
          }
        });
      },
      assignmentsLink() {
        return this.$makeRoute({
          name: 'BuyerAllAssignments',
          query: {
            view: this.showFutureAssignments ? 'current' : 'past',
            sort: 1,
            page: 1
          }
        });
      },
      didYouKnowLink() {
        return this.didYouKnowList?.collection?.publicUrl;
      },
      showdidYouKnowList() {
        return this.didYouKnowList?.articles;
      },
      norwArabicPair() {
        if (this.hotlinePhones.length) {
          return this.hotlinePhones.find((phone) => {
            return phone.langFromId == 3 && phone.langToId == 1;
          });
        }
      },
      hasHotlineData() { return this.hotlinePinCodes.length && this.norwArabicPair && this.interpreterCount; },
      showHotline() { return this.hasHotlineData && this.userCanSeeHotline; },
      dateForCalendar() {
        const todayDate = this.$moment().format('YYYY-MM-DD');
        const tomorrowDate = this.$moment().add(1, 'day').format('YYYY-MM-DD');
        return this.tomorrowCalendar ? tomorrowDate : todayDate;
      },
      favouritIntersProgress() {
        return false;
      },
      isfavouritInters() {
        return !!this.$store.state.b_DashboardStore.favouritInterpreters.data && this.$store.state.b_DashboardStore.favouritInterpreters.data.length;
      },
      isNewsList() {
        return !!this.$store.state.b_DashboardStore.lastPublishedNewsList && this.$store.state.b_DashboardStore.lastPublishedNewsList.article;
      },
      favouritInters() {
        return this.$store.state.b_DashboardStore.favouritInterpreters.data;
      },
      noAssignsText() {
        return this.$gettext('<p style="padding-bottom: 10px">1. Click on \'Book an Interpreter\'</p><p style="padding-bottom: 10px">2. Select the language you need</p><p>3. Click confirm!</p>');
      },
      noFavouriteIntersText() {
        return this.$gettext('Interpreters marked as favorites are displayed here');
      },
      interAmount() {
        return this.clientWidth > 1069 ? 10 : this.clientWidth > 1023 ? 6 : 3;
      },
      favouriteInterLang() {
        return this.$store.getters['UserInfoStore/langFavouriteInters'];
      },
      showStatistic() { return !this.searchQuery && this.showFutureAssignments; },
      assignmentCounters() {
        return [{
          name: this.$gettext('Unconfirmed'),
          value: this.myAssignmentsStatistic.unconfirmed || 0
        }, {
          name: this.$gettext('Offers received'),
          value: this.myAssignmentsStatistic.offersReceived || 0
        }, {
          name: this.$gettext('Confirmed'),
          value: this.myAssignmentsStatistic.confirmed || 0
        }];
      }
    },
    watch: {
      favouriteInterLang() {
        this.$store.dispatch('b_DashboardStore/getFavouritInterpreters', this.favouriteInterLang);
      },
      enterpriseMemberJobsScope() {
        this.$store.commit('b_DashboardStore/removeBDashboardData');
        this.$store.commit('GlobalStore/startProgress');
        this.$options.asyncData({
          store: this.$store,
          moment: this.$moment
        }).then(() => {
          this.$store.commit('GlobalStore/stopProgress');
        });
      }
    },
    methods: {
      ...mapMutations('b_DashboardStore', [
        'setAssignmentsProgress'
      ]),
      closeModal() {
        this.$emit('closemodal');
      },
      openNewsList() {
        this.closeModal();
        this.$store.commit('ModalStore/setModal', {
          component: 'news-list-modal',
        });
      },
      toggleCalendar() {
        let promiseArr = [];

        this.calendarProgress = true;
        if (!this.$store.state.b_DashboardStore.todayJobs) {
          const params = {view: 'day', date: this.$moment().format('YYYY-MM-DD')};
          promiseArr = [...promiseArr, this.$store.dispatch('b_DashboardStore/b_getTodayCalendarJobs', params)];
        }
        if (!this.$store.state.b_DashboardStore.tomorrowJobs) {
          const params = {view: 'day', date: this.$moment(this.$moment().add(1, 'day')).format('YYYY-MM-DD')};
          promiseArr = [...promiseArr, this.$store.dispatch('b_DashboardStore/b_getTomorrowCalendarJobs', params)];
        }
        Promise.all(promiseArr).then(() => {
          this.calendarProgress = false;
          this.tomorrowCalendar = !this.tomorrowCalendar;
        });
      },
      toggleAssignments() {
        let promiseArr = [];

        this.setAssignmentsProgress(true);
        if (!this.$store.state.b_DashboardStore.myAssignments.futureAssignments) {
          const params = {items: 10, page: 1};
          promiseArr = [...promiseArr, this.$store.dispatch('b_DashboardStore/getDashboardFutureAssignments', params)];
        }
        if (!this.$store.state.b_DashboardStore.myAssignments.pastAssignments) {
          const params = {items: 10, page: 1};
          promiseArr = [...promiseArr, this.$store.dispatch('b_DashboardStore/getDashboardPastAssignments', params)];
        }
        Promise.all(promiseArr).then(() => {
          this.setAssignmentsProgress(false);
          this.showFutureAssignments = !this.showFutureAssignments;
        });
      },
      updateClientWidth() {
        this.clientWidth = document.documentElement.clientWidth;
      },
      changeFavouriteInterLang(value) {
        this.$store.commit('UserInfoStore/setFavouriteInterLang', value);
      },
      updateDashboardAssignments() {
        this.$store.dispatch('b_DashboardStore/getDashboardFutureAssignments').then(() => {
          this.intervalCanUpdate = false;
        });
      },
      hideVerifiedEmailModal() {
        this.$cookieManager.setCookie('unverifiedEmailPopup', 'hidden');
      },
      openVerifiedEmailModal() {
        const template = this.$gettext('<span class="email-modal-text">Is <a href="mailto:%{userEmail}" class="sk-link js-copy-to-clipboard">%{userEmail}</a> still your email address?</span> <span class="email-modal-text">We have sent you an email with a link to verify and have full access to your account.</span> <span class="email-modal-text">If you haven\'t received the verification email, please contact us at <a href="mailto:post@salita.no" class="sk-link js-copy-to-clipboard">post@salita.no</a> so that we can help you verify your email address.</span>');

        this.$store.commit('ModalStore/setModal', {
          component: 'confirmation-modal',
          width: 510,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Verify your account'),
            text: this.$gettextInterpolate(template, {userEmail: this.userEmail}),
            context: this,
            modalCallback: this.hideVerifiedEmailModal,
            visibility: {
              hideCancelBtn: true
            },
            btnTexts: {
              actionBtnText: this.$gettext('Skip for now'),
            }
          }
        });
      }
    },
    beforeMount() {
      this.updateClientWidth();
      if (this.$store.state.openAssignModal || this.$cookieManager.getCookie('openAssignModal')) {
        this.$store.commit('ModalStore/setModal', {
          component: 'open-assign-modal',
          data: {
            title: this.$gettext('Permission error')
          }
        });
        this.$store.commit('setOpenAssignModalState', false);
      }
      if (this.$store.getters['UserInfoStore/welcomeModal']) {
        this.$store.dispatch('UserInfoStore/sendWelcome').then(() => {
          this.$store.commit('ModalStore/setModal', {
            component: 'welcome-modal',
            data: {title: ''}
          });
        });
      }
      if (this.isEmailVerificationRequired && !this.$isGodmode() && this.$cookieManager.getCookie('unverifiedEmailPopup') !== 'hidden') {
        this.openVerifiedEmailModal();
      }
    },
    mounted() {
      window.addEventListener('resize', this.updateClientWidth);
      localStorage.removeItem('jobCompletedByColleague');
      localStorage.removeItem('showedUp');
      localStorage.removeItem('jobCompleted');
      localStorage.removeItem('anyOtherProblem');
      localStorage.removeItem('ticketComment');
      localStorage.removeItem('agreeWithReportedDate');
      localStorage.removeItem('problemDescription');
      this.intervalId = setInterval(() => {
        if (this.$moment().minute() % 30 === 0 && this.intervalCanUpdate) {
          this.updateDashboardAssignments();
        }
        if (this.$moment().minute() % 30 !== 0) {
          this.intervalCanUpdate = true;
        }
      }, 60000);
      if (!this.$cookieManager.getCookie('lastUsedType') || this.$cookieManager.getCookie('lastUsedType') !== 'interpretation') {
        this.$cookieManager.setCookie('lastUsedType', 'interpretation');
      }
    },
    beforeDestroy() {
      clearInterval(this.intervalId);
      window.removeEventListener('resize', this.updateClientWidth);
      this.$store.commit('DashboardSearchStore/clearStore');
      this.$store.commit('b_DashboardStore/removeBDashboardData');
    },
    beforeRouteLeave(to, from, next) {
      this.$store.dispatch('ModalStore/closeModal');
      this.$store.commit('TTHotlineStore/clearStore');
      this.$store.commit('EnterpriseStore/clearStore');
      next();
    }
  };
</script>

<style>
  .dashboard__calendar-wrapper .fc-timeGridDay-view .fc-time-grid .fc-slats td {
    height: 1.3em;
  }

  @media (max-width: 1069px) and (min-width: 768px) {
    .bView .dashboard__section .assignment__link:nth-child(6) ~ .assignment__link {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .bView .dashboard__section .assignment__link:nth-child(5) ~ .assignment__link {
      display: none;
    }
  }
</style>

<style scoped src="@assets/css/dashboard_page.css"></style>

<style scoped>
.dashboard__section-title-archive {
  font-size: 12px;
  text-decoration: underline;
}

.did-you-know {
  flex-shrink: 1;
}

.dashboard__section-title {
  font-weight: bold;
}

.dashboard__interpreters-list {
  overflow: auto;
}

.dashboard__top-section {
  padding: 10px;
}

.dashboard__calendar-wrapper {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
}

.dashboard__switchers-cont {
  display: flex;
  align-items: center;
  width: auto;
  font-size: 18px;
}

.dashboard__switcher {
  display: inline-block;
  width: auto;
  color: #a7abbd;
  line-height: 20px;
}

.dashboard__switcher--active {
  color: #000;
}

.dashboard__switcher--active:hover {
  color: #a7abbd;
}

.dashboard__switcher:hover {
  color: #000;
}

.dashboard__switcher:focus {
  box-shadow: var(--default-focus-shadow);
}

.dashboard__switchers-devider {
  display: inline-block;
  width: 2px;
  height: 20px;
  margin: 0 10px;
  background-color: #a7abbd;
}

.dashboard__section-link {
  align-self: center;
  margin-left: auto;
  font-size: 12px;
  text-decoration: underline;
}

.dashboard__section-link:hover {
  color: #7a829d;
}

.dashboard__section-link:focus {
  box-shadow: var(--default-focus-shadow);
}

.dashboard__inter-filter {
  display: flex;
  align-items: center;
  height: 25px;
  color: #646d8c;
  font-size: 12px;
}

.dashboard__lang-select {
  width: 80px;
}

.dashboard__lang-select-descr {
  padding-right: 5px;
  text-align: right;
}

.dashboard__assignment-counters-cont {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.dashboard__assignment-counter {
  display: flex;
  flex-direction: column;
  width: 33.333333%;
  text-align: center;
}

.dashboard__assignment-counter-digit {
  font-weight: bold;
  font-size: 18px;
}

.dashboard__assignment-counter-name {
  font-size: 12px;
}

.news {
  background-color: #d5d3de;
}

.archive {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .dashboard__top-section {
    padding: 10px 20px 20px;
  }

  .dashboard__calendar-wrapper {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: hidden;
  }

  .dashboard__switchers-cont {
    display: flex;
    align-items: center;
    width: auto;
    font-size: 18px;
  }

  .dashboard__switcher {
    display: inline-block;
    width: auto;
    padding: 2px;
    color: #a7abbd;
    line-height: 20px;
  }

  .dashboard__switcher--active {
    color: #000;
  }

  .dashboard__switcher--active:hover {
    color: #a7abbd;
  }

  .dashboard__switcher:hover {
    color: #000;
  }

  .dashboard__switcher:focus {
    outline: var(--default-focus-outline);
  }

  .dashboard__switchers-devider {
    display: inline-block;
    width: 2px;
    height: 20px;
    margin: 0 10px;
    background-color: #a7abbd;
  }

  .dashboard__section-link {
    align-self: center;
    margin-left: auto;
    font-size: 12px;
    text-decoration: underline;
  }

  .dashboard__section-link:hover {
    color: #7a829d;
  }

  .dashboard__section-link:focus {
    outline: var(--default-focus-outline);
  }

  .dashboard__inter-filter {
    display: flex;
    align-items: center;
    height: 25px;
    color: #646d8c;
    font-size: 12px;
  }

  .dashboard__lang-select {
    width: 80px;
  }

  .dashboard__lang-select-descr {
    padding-right: 5px;
    text-align: right;
  }

  .dashboard__assignment-counters-cont {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .dashboard__assignment-counter {
    display: flex;
    flex-direction: column;
    width: 33.333333%;
    text-align: center;
  }

  .dashboard__assignment-counter-digit {
    font-weight: bold;
    font-size: 18px;
  }

  .dashboard__assignment-counter-name {
    font-size: 12px;
  }

  @media (max-width: 767px) {
    .dashboard__top-section {
      padding: 10px 20px 20px;
    }
  }
}
</style>

<style>
.email-modal-text {
  display: block;
  padding-top: 8px;
}
</style>
